<template>
  <div class="inner-layout">
    <div class="main-heading-group">
      <div class="heading-3">{{ data.theName }}
        <div class="desc">{{ data.theSubName }}</div>
      </div>
    </div>
    <div class="item-container column-3">
      <div v-for="(item, index) in data.list" :key="`md-${index}`" class="default-item cursor-pointer" @click="goDetail(item.boIdx)">
        <div>
          <div class="item-img">
            <div class="thumb-img youtube-ratio"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
          </div>
          <div class="item-con">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-info">
              <span class="date">{{ $getDateFormat(item.uploadDate, 'Y.m.d') }}</span>
            </div>
            <div class="item-info2">
              <div class="profile-set">
                <div class="profile-info">
                  <div><span class="nickname">{{ item.chanName }}</span></div>
                </div>
              </div>
              <div class="d-flex">
                <div class="hit"><span class="icon-img icon-eye"></span> {{ item.view ? Number(item.view).numberFormat() : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listMixin from "@/mixins/listMixin"
export default {
  mixins: [listMixin],
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const { result, data } = await this.$api.getMdListByIdx({ idx: this.id })
      if (result === 'success') this.data = data
    },
    goDetail(index) {
      this.setBeforeContent()
      this.$router.push(`/md/${this.id}/${index}`)
    },
  }
}
</script>

